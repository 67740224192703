import React, { lazy, Suspense } from "react"
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))

export default class ZipFinderSmall extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: '',
            showModal: false,
            loading: false
        }

        if (this.props.state !== undefined) {
            this.place =
            {
                name: undefined,
                state:
                {
                    name: this.props.state.name,
                    av: this.props.state.av
                }
            }
        }else{
            this.place = this.props.place;
        }
        this.ondeffCode = 'dumpsterQuoteRequest'
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.service = this.props.env.service !== undefined ? this.props.env.service : 'https://bookable-api-dev.azurewebsites.net';
        this.site = this.props.env.site !== undefined ? this.props.env.site : 'dumpster';
        this.type = this.props.type;
        this.env = this.props.env;
        this.finderopenmodal = this.props.finderopenmodal;

        //Quote request props
        this.proId = this.props.proid;
        this.startVisible = this.props.startVisible ? true : false;

        this.initialValuePoolLimit = 0;
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search)
            if (params.get("test") == "true") {
                this.initialValuePoolLimit = 1;
            }
        }
        this.limitPoolSize = this.initialValuePoolLimit;

        this.modalStyles = {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#e8e8e8',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px 20px',
                minHeight: 'auto',
                width: '600px',
            }
        }
    }

    changeShowModal = () => {
        this.setState({ ...this.state, showModal: !this.state.showModal });
    }

    setLoading = () => {
        this.setState({ ...this.state, loading: !this.state.loading });
    }

    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }

        if (this.props.page == "home") {
            this.getUrl(zip, true);
        } else if (this.env.site === 'dumpster' && this.code != 'comercial-trash-service') {
            this.changeShowModal()
            if (this.code == 'junk-removal') this.ondeffCode = 'junkRemovalQuoteRequest'
            else this.ondeffCode = 'dumpsterQuoteRequest'
        } else {
            if (this.finderopenmodal) {
                this.changeShowModal()
                this.ondeffCode = 'demolitionQuoteRequest'
            } else this.getUrl(zip, true);
        }

    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let endpoint = '';

        let code = this.code;

        switch (this.site) {
            case 'dumpster':
                code = code === 'home' ? 'dumpster' : code;
                endpoint = '/api/v1/kpp/service_types/' + code + '/zip_codes/' + zip;
                break;

            case 'demolition':
                code = code === 'home' || code === 'demolition' ? 'demolition-contractors' : code;
                endpoint = '/api/v1/kpp/keywords/' + code + '/zip_codes/' + zip;
                break;

            default:
                break;
        }

        if (endpoint !== '') {
            const url = this.service + endpoint;

            fetch(url,
                {
                    method: 'get',
                })
                .then((response) => {
                    if (response.status === 400) {
                        return '/redirect/no-zip-found';
                    }

                    return response.json();
                })
                .then((response) => {
                    this.cookies(zip);

                    this.setState({ 'redirect': response });

                    if (redirect) {
                        this.redirect(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    zipTop = () => {
        return (
            <ul class="ziptop">
                <li><img src={'/images/icons/tell-us-about-your-project-icon-1.png'} alt="" /> Tell us about your project</li>
                <li><img src={'/images/icons/get-connected-to-demo-pros-icon-2.png'} alt="" /> Get connected with matched pros</li>
                <li><img src={'/images/icons/get-free-quotes-easily-icon-3.png'} alt="" /> Compare free quotes easily</li>
            </ul>
        )
    }

    getFinder = type => {
                return (
                    <div className="zip-finder-small mb-5">
                        <h3>{this.props.title}</h3>
                        <div className="card">
                            <form onSubmit={this.handleSubmit}>

                                {this.zipTop()}

                                <input
                                    id={this.props.id}
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    placeholder="Enter Zip Code"
                                    onChange={this.handleInputChange}
                                    maxLength="5"
                                />

                                <input className="btn btn-primary" type="submit" value={this.props.button} />
                            </form>
                        </div>
                    </div>
                )
    }

    render() {
        return (<>
            {this.getFinder(this.type)}
            <Modal
                id={1}
                isOpen={this.state.showModal}
                style={this.modalStyles}
                contentLabel="Get a Quote!"
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="modal-header">
                    <button type="button" className="close closermodal" onClick={this.changeShowModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="quote-steps-modal-library">
                  <Suspense fallback={<h1>Loading…</h1>}>
                    <OnDeffImplementation
                        initialData={[{ key: 'pro_id', value: this.proId }, { key: 'limit_poolsize', value: this.limitPoolSize }]}
                        formCode={this.ondeffCode}
                        loadingFunction={this.setLoading}>
                    </OnDeffImplementation>
                  </Suspense>
                </div>
            </Modal></>
        )
    }
}