import React from "react"
import HeaderMenuDumpster from "./header-menu-dumpster"
import ZipFinderKpps from "./zip-finder-kpps";

export default function HeaderDumpster(props) 
{
	const env = props.env;
	const code = props.code;
	const page = props.page
	const button = page == "home" ? "Find Pros" : "Get Started";
	
	return (
		<nav id="sitenav" className="col-12 navbar navbar-expand-md navbar-light">			
			
			<div className="elements">
			
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {document.getElementById('navbarSupportedContent').classList.toggle('show')}}>   
					<span className="sr-only"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>    
				</button>

				<a className="nav-bar brand" href="/" title="Home">
					<img src="/images/logo-dumpster.webp" alt="logo"></img>
				</a>

			</div>
            
			<HeaderMenuDumpster />

			<ZipFinderKpps 
				id={'header-zip-finder'} 
				page={page}
				env={env} 
				type={'small'} 
				code={code} 
				button = {button}
				finderopenmodal={false}
				/>

		</nav>		
	);
}